import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "build-a-declarative-billiards-simulation-with-mobx-canvas-and-konva"
    }}>{`Build a declarative billiards simulation with MobX, Canvas, and Konva`}</h1>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/billiards-start.png",
        "alt": "Billiards game"
      }}></img></p>
    <p>{`We're building a small game. You have 11 glass balls – marbles, if you will.
Grab one, throw it at the others, watch them bounce around. There is no score,
but it looks cool, and it's a fun way to explore how Konva and React give you
interactive Canvas features.`}</p>
    <iframe src="https://swizec.github.io/declarative-canvas-react-konva/" style={{
      border: 0,
      width: "100%",
      height: 500
    }} />
    <p>{`We're using React and Konva to render our 11 marbles on an HTML5 Canvas
element, MobX to drive the animation loop, and D3 to help with collision
detection. Because this example is declarative, we can split it into two parts:`}</p>
    <ul>
      <li parentName="ul">{`Part 1: Rendering the marbles`}</li>
      <li parentName="ul">{`Part 2: Building the physics`}</li>
    </ul>
    <p>{`You can see the finished
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/declarative-canvas-react-konva"
      }}>{`code on Github`}</a>{` and
play around with a
`}<a parentName="p" {...{
        "href": "https://swizec.github.io/declarative-canvas-react-konva/"
      }}>{`hosted version`}</a>{` of
the code you're about to build.`}</p>
    <p>{`I know this example comes late in the book, and you're feeling like you know
all there is to React and visualizations. You can think of this example as
practice. Plus it's a good way to learn the basics of MobX.`}</p>
    <h2 {...{
      "id": "decorators"
    }}>{`Decorators`}</h2>
    <p>{`Before we begin, let me tell you about decorators.`}</p>
    <p>{`MobX embraces them to make its API easier to use. You can use MobX without
decorators, but decorators make it better.`}</p>
    <p>{`A couple years ago, decorators got very close to becoming an official spec,
then got held back. I don't know `}<em parentName="p">{`why`}</em>{`, but they're a great feature whose
syntax is unlikely to change. So even if MobX has to change its implementation
when decorators do land in the JavaScript spec, you're not likely to have to
change anything.`}</p>
    <p>{`You can think of decorators as function wrappers. Instead of code like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`inject("store", ({ store }) => <div>A thing with {store.value}</div>)
`}</code></pre>
    <p>{`You can write the same code like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`@inject('store')
({ store }) => <div>A thing with {store.value}</div>
`}</code></pre>
    <p>{`Not much of a difference, but it becomes better looking when you work with
classes or combine multiple decorators. That's when they shine. No more
`}<inlineCode parentName="p">{`})))}))`}</inlineCode>{` at the end of your functions.`}</p>
    <p>{`By the way, `}<inlineCode parentName="p">{`inject`}</inlineCode>{` is to MobX much like `}<inlineCode parentName="p">{`connect`}</inlineCode>{` is to Redux. I'll explain
in a bit.`}</p>
    <h2 {...{
      "id": "part-0-some-setup"
    }}>{`Part 0: Some setup`}</h2>
    <p>{`Because decorators aren't in the JavaScript spec, we have to tweak how we start
our project. We can still use `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{`, but there's an additional
step.`}</p>
    <p>{`You should start a new project like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-{caption=\"Create",
        "metastring": "the billiards game project\"}",
        "the": true,
        "billiards": true,
        "game": true,
        "project\"}": true
      }}>{`$ create-react-app billiards-game --scripts-version custom-react-scripts
`}</code></pre>
    <p>{`This creates a new directory with a full setup for React. Just like you're used
to.`}</p>
    <p>{`The addition of `}<inlineCode parentName="p">{`--scripts-version custom-react-scripts`}</inlineCode>{` employs @kitze's
`}<a parentName="p" {...{
        "href": "https://github.com/kitze/custom-react-scripts"
      }}>{`custom-react-scripts`}</a>{` project
to give us more configuration options. Like the ability to enable decorators.`}</p>
    <p>{`We enable them in the `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file. Add this line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-{caption=\"Add",
        "metastring": "to .env settings\"}",
        "to": true,
        ".env": true,
        "settings\"}": true
      }}>{`// billiards-game/.env
// ...
REACT_APP_DECORATORS=true
`}</code></pre>
    <p>{`No installation necessary. I think `}<inlineCode parentName="p">{`custom-react-scripts`}</inlineCode>{` uses the
`}<inlineCode parentName="p">{`transform-decorators-legacy`}</inlineCode>{` Babel plugin behind the scenes. It's
pre-installed, and we enable it with that `}<inlineCode parentName="p">{`.env`}</inlineCode>{` change.`}</p>
    <p>{`Before we begin, you should install some other dependencies as well:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-{caption=\"Install",
        "metastring": "libraries\"}",
        "libraries\"}": true
      }}>{`$ npm install --save konva react-konva mobx mobx-react \\
    d3-timer d3-scale d3-quadtree
`}</code></pre>
    <p>{`This gives you Konva, MobX, and the parts of D3 that we need. You're now ready
to build the billiards game.`}</p>
    <h2 {...{
      "id": "a-quick-mobx-primer"
    }}>{`A quick MobX primer`}</h2>
    <p>{`Explaining MobX in detail is beyond the scope of this book. You can learn it by
osmosis as you follow the code in our billiards example.`}</p>
    <p>{`That said, here's a quick rundown of the concepts we're using.`}</p>
    <p>{`MobX is based on reactive programming. There are values that are observable and
functions that react when those values change. MobX ensures only the minimal
possible set of observers is triggered on every change.`}</p>
    <p>{`So, we have:`}</p>
    <p><inlineCode parentName="p">{`@observable`}</inlineCode>{` – a property whose changes observers subscribe to `}<inlineCode parentName="p">{`@observer`}</inlineCode>{` – a
component whose `}<inlineCode parentName="p">{`render()`}</inlineCode>{` method observes values `}<inlineCode parentName="p">{`@computed`}</inlineCode>{` – a method whose
value can be fully derived from observables `}<inlineCode parentName="p">{`@action`}</inlineCode>{` – a method that changes
state, analogous to a Redux reducer `}<inlineCode parentName="p">{`@inject`}</inlineCode>{` – a decorator that injects global
stores into a component's props`}</p>
    <p>{`That's all you need to know. Once your component is an `}<inlineCode parentName="p">{`@observer`}</inlineCode>{`, you never
have to worry about `}<em parentName="p">{`what`}</em>{` it's observing. MobX ensures it reacts to changes in
values used during rendering.`}</p>
    <p>{`Making your component an observer and injecting the global store is the same as
using `}<inlineCode parentName="p">{`connect`}</inlineCode>{` in Redux. It gives your component access to your state, and it
triggers a re-render when something changes.`}</p>
    <p>{`Importantly, it `}<em parentName="p">{`doesn't`}</em>{` trigger a re-render when something that the component
isn't using changes. That little tidbit is what makes many other reactive
libraries difficult to use.`}</p>
    <h2 {...{
      "id": "part-1-rendering-our-marbles"
    }}>{`Part 1: Rendering our marbles`}</h2>
    <p>{`Our marbles render on Canvas using Konva. Each marble is its own sprite
rendered as a Konva element. This makes it easier to implement user and marble
interactions.`}</p>
    <p>{`Rendering happens in 3 components:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`App`}</inlineCode>{` holds everything together`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MarbleList`}</inlineCode>{` renders a list of marbles`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Marble`}</inlineCode>{` renders an individual marble`}</li>
    </ul>
    <p>{`We're also using 2 MobX stores:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Sprite`}</inlineCode>{` to load the marble sprite and define coordinates within`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Physics`}</inlineCode>{` as our physics engine`}</li>
    </ul>
    <p><inlineCode parentName="p">{`Sprite`}</inlineCode>{` and `}<inlineCode parentName="p">{`Physics`}</inlineCode>{` are hold almost all of our game logic. A bit of drag &
drop logic goes in the `}<inlineCode parentName="p">{`Marble`}</inlineCode>{` component. Other than that, all our components
are presentational. They get props and render stuff.`}</p>
    <p>{`Let's start with `}<inlineCode parentName="p">{`App`}</inlineCode>{` and work our way down.`}</p>
    <h3 {...{
      "id": "app"
    }}>{`App`}</h3>
    <p>{`Our `}<inlineCode parentName="p">{`App`}</inlineCode>{` component doesn't do much. It imports MobX stores, triggers sprite
loading, and starts the game loop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/App.js

import React, { Component } from "react"
import { Provider as MobXProvider, observer } from "mobx-react"

import Physics from "../logic/Physics"
import Sprite from "../logic/Sprite"
import MarbleList from "./MarbleList"

@observer
class App extends Component {
  componentDidMount() {
    Sprite.loadSprite(() => Physics.startGameLoop())
  }

  render() {
    return (
      <div className="App">
        <div className="App-header">
          <h2>Elastic collisions</h2>
          <p>Rendered on canvas, built with React and Konva</p>
        </div>
        <div className="App-intro">
          <MobXProvider physics={Physics} sprite={Sprite}>
            <MarbleList />
          </MobXProvider>
        </div>
      </div>
    )
  }
}

export default App
`}</code></pre>
    <p>{`We import our dependencies: React itself, a `}<inlineCode parentName="p">{`MobXProvider`}</inlineCode>{` that's similar to
the Redux provider (puts stuff in react context), both of our MobX stores which
export singleton instances, and the main `}<inlineCode parentName="p">{`MarbleList`}</inlineCode>{` component.`}</p>
    <p><inlineCode parentName="p">{`App`}</inlineCode>{` itself is a full featured component that initiates sprite loading in
`}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` and calls `}<inlineCode parentName="p">{`startGameLoop`}</inlineCode>{` when the sprite is ready. We know
the sprite is ready because it calls a callback. You'll see how that works in a
bit.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`render`}</inlineCode>{` method outputs some descriptive text and the `}<inlineCode parentName="p">{`MarbleList`}</inlineCode>{`
component wrapped in a `}<inlineCode parentName="p">{`MobXProvider`}</inlineCode>{`. The provider puts instances of our
stores – `}<inlineCode parentName="p">{`sprite`}</inlineCode>{` and `}<inlineCode parentName="p">{`physics`}</inlineCode>{` – in React context.`}</p>
    <p>{`This makes them available to all child components via the `}<inlineCode parentName="p">{`inject`}</inlineCode>{` decorator.`}</p>
    <h3 {...{
      "id": "marblelist"
    }}>{`MarbleList`}</h3>
    <p><inlineCode parentName="p">{`MarbleList`}</inlineCode>{` is an important component that renders the whole game, yet it can
still be small and functional. Every prop it needs comes from our two stores.`}</p>
    <p>{`Like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/MarbleList.js

import React from "react"
import { inject, observer } from "mobx-react"
import { Stage, Layer, Group } from "react-konva"

import Marble from "./Marble"

const MarbleList = inject(
  "physics",
  "sprite"
)(
  observer(({ physics, sprite }) => {
    const { width, height, marbles } = physics
    const { marbleTypes } = sprite

    return (
      <Stage width={width} height={height}>
        <Layer>
          <Group>
            {marbles.map(({ x, y, id }, i) => (
              <Marble
                x={x}
                y={y}
                type={marbleTypes[i % marbleTypes.length]}
                draggable="true"
                id={id}
                key={\`marble-\${id}\`}
              />
            ))}
          </Group>
        </Layer>
      </Stage>
    )
  })
)

export default MarbleList
`}</code></pre>
    <p>{`We import dependencies and create a `}<inlineCode parentName="p">{`MarbleList`}</inlineCode>{` component. Instead of
decorators, we're using with functional composition.`}</p>
    <p>{`This shows you that MobX `}<em parentName="p">{`can`}</em>{` work without decorators, but there's no deep
reason behind this choice. Over time, I've developed a preference for
composition for functional components and decorators for class-based
components.`}</p>
    <p><inlineCode parentName="p">{`inject`}</inlineCode>{` takes values out of context and puts them in component props.
`}<inlineCode parentName="p">{`observer`}</inlineCode>{` declares that our component observes those props and reacts to them.`}</p>
    <p>{`It's generally a good idea to use both `}<inlineCode parentName="p">{`inject`}</inlineCode>{` and `}<inlineCode parentName="p">{`observer`}</inlineCode>{` together. I have
yet to find a case where you need just one or the other.`}</p>
    <p>{`The rendering itself takes values out of our stores and returns a Konva `}<inlineCode parentName="p">{`Stage`}</inlineCode>{`
with a single `}<inlineCode parentName="p">{`Layer`}</inlineCode>{`, which contains a `}<inlineCode parentName="p">{`Group`}</inlineCode>{`. Inside this group is our list
of marbles.`}</p>
    <p>{`Each marble gets a position, a `}<inlineCode parentName="p">{`type`}</inlineCode>{` that defines how it looks, an `}<inlineCode parentName="p">{`id`}</inlineCode>{`, and a
`}<inlineCode parentName="p">{`key`}</inlineCode>{`. We set `}<inlineCode parentName="p">{`draggable`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{` so Konva knows that this element is
draggable.`}</p>
    <p>{`Yes, that means we get draggability on an HTML5 Canvas without any extra
effort. I like that.`}</p>
    <h3 {...{
      "id": "marble"
    }}>{`Marble`}</h3>
    <p>{`Each `}<inlineCode parentName="p">{`Marble`}</inlineCode>{` component renders a single marble and handles dragging and
dropping. That's how you "shoot" marbles.`}</p>
    <p>{`Dragging and dropping creates a vector that accelerates, or shoots, the marble
in a certain direction with a certain speed. Putting this logic in the
component itself makes sense because the rest of our game only cares about that
final vector.`}</p>
    <p>{`The Marble component looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Marble.js

import React, { Component } from "react"
import { Circle } from "react-konva"
import { inject, observer } from "mobx-react"

@inject("physics", "sprite")
@observer
class Marble extends Component {
  onDragStart = () => {
    // set drag starting position
  }

  onDragMove = () => {
    // update marble position
  }

  onDragEnd = () => {
    // shoot the marble
  }

  render() {
    const { sprite, type, draggable, id, physics } = this.props
    const MarbleDefinitions = sprite.marbleDefinitions
    const { x, y, r } = physics.marbles[id]

    return (
      <Circle
        x={x}
        y={y}
        radius={r}
        fillPatternImage={sprite.sprite}
        fillPatternOffset={MarbleDefinitions[type]}
        fillPatternScale={{ x: (r * 2) / 111, y: (r * 2) / 111 }}
        shadowColor={MarbleDefinitions[type].c}
        shadowBlur="15"
        shadowOpacity="1"
        draggable={draggable}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onDragMove={this.onDragMove}
        ref="circle"
      />
    )
  }
}

export default Marble
`}</code></pre>
    <p>{`We `}<inlineCode parentName="p">{`@inject`}</inlineCode>{` both stores into our component and make it an `}<inlineCode parentName="p">{`@observer`}</inlineCode>{`. The
`}<inlineCode parentName="p">{`render`}</inlineCode>{` method takes values out of our stores and renders a Konva `}<inlineCode parentName="p">{`Circle`}</inlineCode>{`.
The circle uses a chunk of our sprite as its background, has a colorful shadow,
and has a bunch of drag callbacks.`}</p>
    <p>{`Those callbacks make our game playable.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`onDragStart`}</inlineCode>{`, we store the starting position of the dragged marble. In
`}<inlineCode parentName="p">{`onDragMove`}</inlineCode>{`, we update the marble's position in the store, which makes it
possible for other marbles to bounce off of ours while it's moving, and in
`}<inlineCode parentName="p">{`onDragEnd`}</inlineCode>{`, we shoot the marble.`}</p>
    <p>{`Shoot direction depends on how we dragged. That's why we need the starting
positions.`}</p>
    <p>{`Drag callbacks double as MobX actions. Makes our code simpler. Instead of
specifying an extra `}<inlineCode parentName="p">{`@action`}</inlineCode>{` in the MobX store, we manipulate the values
directly.`}</p>
    <p>{`MobX makes this okay. It keeps everything in sync and our state easy to
understand. MobX even batches value changes before triggering re-renders.`}</p>
    <p>{`The code inside those callbacks is pretty mathsy.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Marble.js

class Marble extends Component {
  onDragStart = () => {
    const { physics, id } = this.props

    this.setState({
      origX: physics.marbles[id].x,
      origY: physics.marbles[id].y,
      startTime: new Date(),
    })
  }

  onDragMove = () => {
    const { physics, id } = this.props
    const { x, y } = this.refs.circle.attrs

    physics.marbles[id].x = x
    physics.marbles[id].y = y
  }

  onDragEnd = () => {
    const { physics } = this.props,
      circle = this.refs.circle,
      { origX, origY } = this.state,
      { x, y } = circle.attrs

    const delta_t = new Date() - this.state.startTime,
      dist = (x - origX) ** 2 + (y - origY) ** 2,
      v = Math.sqrt(dist) / (delta_t / 16) // distance per frame (= 16ms)

    physics.shoot(
      {
        x: x,
        y: y,
        vx: (x - origX) / (v / 3), // /3 is a speedup factor
        vy: (y - origY) / (v / 3),
      },
      this.props.id
    )
  }

  // ...
}
`}</code></pre>
    <p>{`In `}<inlineCode parentName="p">{`onDragStart`}</inlineCode>{`, we store original coordinates and start time in local state.
These are temporary values that nothing outside this user action cares about.
Local state makes sense.`}</p>
    <p>{`We'll use them to determine how far the user dragged our marble.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`onDragMove`}</inlineCode>{` we update the MobX store with new coordinates for this
particular marble. You might think we're messing with mutable state here, and
we `}<em parentName="p">{`might`}</em>{` be, but these are MobX observables. They're wrapped in setters that
ensure everything is kept in sync, changes logged, observers notified, etc.`}</p>
    <p><inlineCode parentName="p">{`onDragEnd`}</inlineCode>{` shoots the marble. We calculate drag speed and direction, then we
call the `}<inlineCode parentName="p">{`shoot()`}</inlineCode>{` action on the `}<inlineCode parentName="p">{`physics`}</inlineCode>{` store.`}</p>
    <p>{`The math we're doing is called
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Euclidean_distance"
      }}>{`euclidean distance`}</a>{` by the
way. Distance between two points is the root of the sum of squares of distance
on each axis.`}</p>
    <h3 {...{
      "id": "sprite-store"
    }}>{`Sprite store`}</h3>
    <p>{`Now that we know how rendering works, we need to load our sprite. It's an icon
set I bought online. Can't remember where or who from.`}</p>
    <p>{`Here's what it looks like:`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/monster-marbles-sprite-sheets.jpg",
        "alt": "Marbles sprite"
      }}></img></p>
    <p>{`To use this sprite, we need two things:`}</p>
    <ol>
      <li parentName="ol">{`A way to tell where on the image each marble lies`}</li>
      <li parentName="ol">{`A MobX store that loads the image into memory`}</li>
    </ol>
    <p>{`The first is a `}<inlineCode parentName="p">{`MarbleDefinitions`}</inlineCode>{` dictionary. We used it in `}<inlineCode parentName="p">{`Marble`}</inlineCode>{`
component's render method. If you're playing along, you should copy paste this.
Too much typing 😃`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/logic/Sprite.js

const MarbleDefinitions = {
  dino: { x: -222, y: -177, c: "#8664d5" },
  redHeart: { x: -222, y: -299, c: "#e47178" },
  sun: { x: -222, y: -420, c: "#5c96ac" },

  yellowHeart: { x: -400, y: -177, c: "#c8b405" },
  mouse: { x: -400, y: -299, c: "#7d7e82" },
  pumpkin: { x: -400, y: -420, c: "#fa9801" },

  frog: { x: -576, y: -177, c: "#98b42b" },
  moon: { x: -575, y: -299, c: "#b20717" },
  bear: { x: -576, y: -421, c: "#a88534" },
}

export { MarbleDefinitions }
`}</code></pre>
    <p>{`Each type of marble has a name, a coordinate, and a color. The coordinate tells
us where on the sprite image it is, and the color helps us create a nice
shadow.`}</p>
    <p>{`All values painstakingly assembled by hand. You're welcome. :relieved:`}</p>
    <p>{`The MobX store that loads our sprite into memory and helps us use it looks like
this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/logic/Sprite.js

import { observable, action, computed } from "mobx"
import MarbleSprite from "../monster-marbles-sprite-sheets.jpg"

class Sprite {
  @observable sprite = null

  @action loadSprite(callback = () => null) {
    const sprite = new Image()
    sprite.src = MarbleSprite

    sprite.onload = () => {
      this.sprite = sprite

      callback()
    }
  }

  @computed get marbleTypes() {
    return Object.keys(MarbleDefinitions)
  }

  @computed get marbleDefinitions() {
    return MarbleDefinitions
  }
}

export default new Sprite()
`}</code></pre>
    <p>{`A MobX store is a JavaScript object. It has `}<inlineCode parentName="p">{`@observable`}</inlineCode>{` values, `}<inlineCode parentName="p">{`@actions`}</inlineCode>{`,
and `}<inlineCode parentName="p">{`@computed`}</inlineCode>{` getters. That's all there is to it.`}</p>
    <p>{`No complicated reducers and action generators. Just JavaScript functions and
properties. There's plenty going on behind the scenes, but we don't have to
think about it.`}</p>
    <p>{`That's why I like MobX more than Redux. Feels easier to use 🤫`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`Sprite`}</inlineCode>{` store, we have an `}<inlineCode parentName="p">{`@observable sprite`}</inlineCode>{`. Changing this value
triggers a re-render in al `}<inlineCode parentName="p">{`@observer`}</inlineCode>{` components that rely on it. In our case
that's every marble.`}</p>
    <p>{`Then we have a `}<inlineCode parentName="p">{`loadSprite`}</inlineCode>{` action. It creates a new `}<inlineCode parentName="p">{`Image`}</inlineCode>{` object and loads
the sprite. After the image loads, we set `}<inlineCode parentName="p">{`this.sprite`}</inlineCode>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`@computed`}</inlineCode>{` getters make it easier to access `}<inlineCode parentName="p">{`MarbleDefinitions`}</inlineCode>{`.
`}<inlineCode parentName="p">{`marbleTypes`}</inlineCode>{` gives us a list of available types of marbles and
`}<inlineCode parentName="p">{`marbleDefinitions`}</inlineCode>{` returns the definitions object.`}</p>
    <p>{`Running your code won't work just yet. We need the physics store first because
it defines marble positions.`}</p>
    <h2 {...{
      "id": "part-2-building-the-physics"
    }}>{`Part 2: Building the physics`}</h2>
    <p>{`Our whole physics engine fits into a single MobX store. It contains the
collision detection, marble movement calculations, and drives the game loop
itself.`}</p>
    <p>{`The general approach goes like this:`}</p>
    <ol>
      <li parentName="ol">{`Have an observable array of marbles`}</li>
      <li parentName="ol">{`Run a `}<inlineCode parentName="li">{`simulationStep`}</inlineCode>{` on each `}<inlineCode parentName="li">{`requestAnimationFrame`}</inlineCode>{` using `}<inlineCode parentName="li">{`d3.timer`}</inlineCode></li>
      <li parentName="ol">{`Change marble positions and speed`}</li>
      <li parentName="ol">{`MobX observables and observers trigger re-renders of marbles that move`}</li>
    </ol>
    <p>{`The
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/declarative-canvas-react-konva/blob/master/src/logic/Physics.js"
      }}>{`whole Physics store`}</a>{`
is some 120 lines of code. We'll go slow. Here's the skeleton:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/logic/Physics.js

class Physics {
  @observable MarbleR = 25
  @observable width = 800
  @observable height = 600
  @observable marbles = []
  timer = null

  @computed get initialPositions() {}

  @action startGameLoop() {}

  @action simulationStep() {}

  @action shoot({ x, y, vx, vy }, i) {}
}
`}</code></pre>
    <p>{`We have four observable properties, a `}<inlineCode parentName="p">{`timer`}</inlineCode>{`, a `}<inlineCode parentName="p">{`@computed`}</inlineCode>{` property for
initial positions, and 3 actions. `}<inlineCode parentName="p">{`startGameLoop`}</inlineCode>{` starts our game,
`}<inlineCode parentName="p">{`simulationStep`}</inlineCode>{` holds the main logic, and `}<inlineCode parentName="p">{`shoot`}</inlineCode>{` shoots a particular marble.`}</p>
    <p>{`Let's walk through.`}</p>
    <h3 {...{
      "id": "initialpositions"
    }}>{`initialPositions`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/logic/Physics.js
class Physics {
  // ..
  @computed get initialPositions() {
    const { width, height, MarbleR } = this,
      center = width / 2

    const lines = 4,
      maxY = 200

    let marbles = range(lines, 0, -1)
      .map((y) => {
        if (y === lines)
          return [{ x: center, y: maxY, vx: 0, vy: 0, r: this.MarbleR }]

        const left = center - y * (MarbleR + 5),
          right = center + y * (MarbleR + 5)

        return range(left, right, MarbleR * 2 + 5).map((x) => ({
          x: x,
          y: maxY - y * (MarbleR * 2 + 5),
          vx: 0,
          vy: 0,
          r: this.MarbleR,
        }))
      })
      .reduce((acc, pos) => acc.concat(pos), [])

    marbles = [].concat(marbles, {
      x: width / 2,
      y: height - 150,
      vx: 0,
      vy: 0,
      r: this.MarbleR,
    })

    marbles.forEach((m, i) => (marbles[i].id = i))

    return marbles
  }
  // ..
}
`}</code></pre>
    <p>{`Believe it or not, this is like one of those `}<em parentName="p">{`"Arrange things in a triangle"`}</em>{`
puzzles you'd see in an old Learn How To Program book. Or a whiteboard
interview.`}</p>
    <p>{`It took me 3 hours to build. Easy to get wrong, fiddly to implement.`}</p>
    <p>{`We start with a `}<inlineCode parentName="p">{`range`}</inlineCode>{` of numbers. From `}<inlineCode parentName="p">{`lines`}</inlineCode>{` to `}<inlineCode parentName="p">{`0`}</inlineCode>{` in descending order. We
iterate through this list of rows and change each into a list of marbles.`}</p>
    <p>{`4 marbles in the first row, 3 in the next, all the way down to 1 in last row.`}</p>
    <p>{`For each row, we calculate how much space we have on the `}<inlineCode parentName="p">{`left`}</inlineCode>{` and `}<inlineCode parentName="p">{`right`}</inlineCode>{` of
the center and make a `}<inlineCode parentName="p">{`range`}</inlineCode>{` of horizontal positions from `}<inlineCode parentName="p">{`left`}</inlineCode>{` to `}<inlineCode parentName="p">{`right`}</inlineCode>{`
with a step of "1 marble size". Using these positions and the known row, we
create marbles as needed.`}</p>
    <p>{`We use a `}<inlineCode parentName="p">{`.reduce`}</inlineCode>{` to flatten nested arrays and add the last marble. That's a
corner case I couldn't solve elegantly, but I'm sure it's possible.`}</p>
    <p>{`In the end, we add an `}<inlineCode parentName="p">{`id`}</inlineCode>{` to each marble. We're using index as the id, that's
true, but that still ensures we use consistent values throughout our app.
Positions in the array may change.`}</p>
    <h3 {...{
      "id": "shoot-and-startgameloop"
    }}>{`shoot and startGameLoop`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/logic/Physics.js
class Physics {
  // ...

  @action startGameLoop() {
    this.marbles = this.initialPositions

    this.timer = timer(() => this.simulationStep())
  }

  // ...

  @action shoot({ x, y, vx, vy }, i) {
    const maxSpeed = 20

    this.marbles[i].x = x
    this.marbles[i].y = y
    this.marbles[i].vx = vx < maxSpeed ? vx : maxSpeed
    this.marbles[i].vy = vy < maxSpeed ? vy : maxSpeed
  }
}
`}</code></pre>
    <p><inlineCode parentName="p">{`shoot`}</inlineCode>{` and `}<inlineCode parentName="p">{`startGameLoop`}</inlineCode>{` are the simplest functions in our physics engine.
`}<inlineCode parentName="p">{`startGameLoop`}</inlineCode>{` gets the initial `}<inlineCode parentName="p">{`marbles`}</inlineCode>{` array and starts a D3 timer. `}<inlineCode parentName="p">{`shoot`}</inlineCode>{`
updates a specific marble's coordinates and speed vector.`}</p>
    <p>{`👌`}</p>
    <h4 {...{
      "id": "simulationstep--where-collisions-collision"
    }}>{`simulationStep – where collisions collision`}</h4>
    <p>{`Here comes the fun part. The one with our game loop.`}</p>
    <p>{`There's also a video explaining how this works 👉
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=H84fmXjTElM"
      }}>{`Watch it on YouTube`}</a>{`. With
hand-drawn sketches that explain the math, and I think that's neat.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`
@action simulationStep() {
    const { width, height, MarbleR } = this;

    const moveMarble = ({x, y, vx, vy, id}) => {
        let _vx = ((x+vx < MarbleR) ? -vx : (x+vx > width-MarbleR) ? -vx : vx)*.99,
            _vy = ((y+vy < MarbleR) ? -vy : (y+vy > height-MarbleR) ? -vy : vy)*.99;

        // nearest marble is a collision candidate
        const subdividedSpace = quadtree().extent([[-1, -1],
                                                   [this.width+1, this.height+1]])
                                          .x(d => d.:satisfied:
                                          .y(d => d.y)
                                          .addAll(this.marbles
                                                      .filter(m => id !== m.id)),
              candidate = subdividedSpace.find(x, y, MarbleR*2);

        if (candidate) {

            // borrowing @air_hadoken's implementation from here:
            // github.com/airhadoken/game_of_circles/
            //   blob/master/circles.js#L64
            const cx = candidate.x,
                  cy = candidate.y,
                  normx = cx - x,
                  normy = cy - y,
                  dist = (normx ** 2 + normy ** 2),
                  c = (_vx * normx + _vy * normy) / dist * 2.3;

            _vx = (_vx - c * normx)/2.3;
            _vy = (_vy - c * normy)/2.3;

            candidate.vx += -_vx;
            candidate.vy += -_vy;
            candidate.x += -_vx;
            candidate.y += -_vy;
        }

        return {
            x: x + _vx,
            y: y + _vy,
            vx: _vx,
            vy: _vy
        }
    };

    this.marbles.forEach((marble, i) => {
        const { x, y, vx, vy } = moveMarble(marble);

        this.marbles[i].x = x;
        this.marbles[i].y = y;
        this.marbles[i].vx = vx;
        this.marbles[i].vy = vy;
    });
}
`}</code></pre>
    <p>{`That's a lot of code 😅. Let's break it down.`}</p>
    <p>{`You can think of `}<inlineCode parentName="p">{`simulationStep`}</inlineCode>{` as a function and a loop. At the bottom,
there is a `}<inlineCode parentName="p">{`.forEach`}</inlineCode>{` that applies a `}<inlineCode parentName="p">{`moveMarble`}</inlineCode>{` function to each marble.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`this.marbles.forEach((marble, i) => {
  const { x, y, vx, vy } = moveMarble(marble)

  this.marbles[i].x = x
  this.marbles[i].y = y
  this.marbles[i].vx = vx
  this.marbles[i].vy = vy
})
`}</code></pre>
    <p>{`We iterate over the list of marbles, feed them into `}<inlineCode parentName="p">{`moveMarble`}</inlineCode>{`, get new
properties, and save them in the main marbles array. MobX `}<em parentName="p">{`should`}</em>{` allows us to
change these values inside `}<inlineCode parentName="p">{`moveMarble`}</inlineCode>{` and let MobX observables do the heavy
lifting, but more explicit code is easier to read.`}</p>
    <h5 {...{
      "id": "movemarble"
    }}>{`moveMarble`}</h5>
    <p><inlineCode parentName="p">{`moveMarble`}</inlineCode>{` is itself a hairy function. Stuff happens in 3 steps:`}</p>
    <ol>
      <li parentName="ol">{`Handle collisions with walls`}</li>
      <li parentName="ol">{`Find collision with closest other marble`}</li>
      <li parentName="ol">{`Handle collision with marble`}</li>
    </ol>
    <p><strong parentName="p">{`Handling collisions with walls`}</strong>{` happens in two lines of code. One per axis.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`let _vx = (x + vx < MarbleR ? -vx : x + vx > width - MarbleR ? -vx : vx) * 0.99,
  _vy = (y + vy < MarbleR ? -vy : y + vy > height - MarbleR ? -vy : vy) * 0.99
`}</code></pre>
    <p>{`Nested ternary expressions are kinda messy, but good enough. If a marble is
beyond any boundary, we reverse its direction. We `}<em parentName="p">{`always`}</em>{` apply a `}<inlineCode parentName="p">{`.99`}</inlineCode>{`
friction coefficient so that marbles slow down.`}</p>
    <p><strong parentName="p">{`Finding collisions`}</strong>{` with the next closest marble happens using a quadtree.
Since we don't have too many marbles, we can build a new quadtree every time.`}</p>
    <p>{`{aside} A quadtree is a way to subdivide space into areas. It lets us answer
the question of "What's close enough to me to possibly touch me?" without
making too many position comparisons.`}</p>
    <p>{`Checking every marble with every other marble produces 81 comparisons. Versus 2
comparisons using a quadtree. {/aside}`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// nearest marble is a collision candidate
const subdividedSpace = quadtree().extent([[-1, -1],
                                           [this.width+1, this.height+1]])
                                  .x(d => d.:satisfied:
                                  .y(d => d.y)
                                  .addAll(this.marbles
                                              .filter(m => id !== m.id)),
      candidate = subdividedSpace.find(x, y, MarbleR*2);
`}</code></pre>
    <p>{`We're using `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-quadtree"
      }}><inlineCode parentName="a">{`d3-quadtree`}</inlineCode></a>{` for the quadtree
implementation. It takes an `}<inlineCode parentName="p">{`extent`}</inlineCode>{`, which tells it how big our space is. An
`}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` accessor tells it how to get coordinates out of our marble objects,
and we use `}<inlineCode parentName="p">{`addAll`}</inlineCode>{` to fill the quadtree with marbles.`}</p>
    <p>{`To avoid detecting each marble as colliding with itself, we take each marble
out of our list before feeding the quadtree.`}</p>
    <p>{`Once we have a quadtree, we use `}<inlineCode parentName="p">{`.find`}</inlineCode>{` to look for the nearest marble within
two radiuses – `}<inlineCode parentName="p">{`MarbleR*2`}</inlineCode>{` – of the current marble. That's exactly the one
we're colliding with! 😄`}</p>
    <p><strong parentName="p">{`Handling collisions with marbles`}</strong>{` involves math. The sort of thing you think
you remember from high school, and suddenly realize you don't when the time
comes to use it.`}</p>
    <p>{`Code looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (candidate) {
  // borrowing @air_hadoken's implementation from here:
  // github.com/airhadoken/game_of_circles/
  //   blob/master/circles.js#L64
  const cx = candidate.x,
    cy = candidate.y,
    normx = cx - x,
    normy = cy - y,
    dist = normx ** 2 + normy ** 2,
    c = ((_vx * normx + _vy * normy) / dist) * 2.3

  _vx = (_vx - c * normx) / 2.3
  _vy = (_vy - c * normy) / 2.3

  candidate.vx += -_vx
  candidate.vy += -_vy
  candidate.x += -_vx
  candidate.y += -_vy
}

return {
  x: x + _vx,
  y: y + _vy,
  vx: _vx,
  vy: _vy,
}
`}</code></pre>
    <p>{`Ok, the `}<inlineCode parentName="p">{`return`}</inlineCode>{` statement isn't about handling collisions. It updates the
current marble.`}</p>
    <p>{`The rest looks like magic. I implemented it and it still looks like magic.`}</p>
    <p>{`You can think of `}<inlineCode parentName="p">{`[normx, normy]`}</inlineCode>{` as a vector that points from current marble
to collision candidate. It gives us bounce direction. We use the
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Euclidean_distance"
      }}>{`euclidean distance`}</a>{` formula
to calculate the length of this vector. The distance between the centers of
both marbles.`}</p>
    <p>{`Then we calculate the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Dot_product"
      }}>{`dot product`}</a>{`
between our marble's speed vector and the collision direction vector. And we
normalize it by distance. Multiplying distance by `}<inlineCode parentName="p">{`2`}</inlineCode>{` accounts for there being
two marbles in the collision. That extra `}<inlineCode parentName="p">{`.3`}</inlineCode>{` made the simulation look better.`}</p>
    <p>{`Fiddling and experimentation are your best tools for magic values like that
😉`}</p>
    <p>{`Then we use the dot product scalar to adjust the marble's speed vector.
Dividing by `}<inlineCode parentName="p">{`2`}</inlineCode>{` takes into account that half the energy goes to the other
marble. This is true because we assume their masses are equal.`}</p>
    <p>{`Finally, we update the `}<inlineCode parentName="p">{`candidate`}</inlineCode>{` marble and make sure it bounces off as well.
We do it additively because that's how it happens in real life.`}</p>
    <p>{`Two marbles traveling towards each other in exactly opposite directions with
exactly the same speed, will stop dead and stay there. As soon as there's any
misalignment, deflection happens. If one is stationary, it starts moving. If
it's moving in the same direction, it speeds up… etc.`}</p>
    <p>{`The end result is
`}<a parentName="p" {...{
        "href": "https://swizec.github.io/declarative-canvas-react-konva/"
      }}>{`a decent-looking simulation of billiards`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      